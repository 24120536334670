import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './TutorApplications.css';

function TutorApplications() {
    const [applications, setApplications] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredApplications, setFilteredApplications] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const applicationsPerPage = 6;
    const navigate = useNavigate();

    useEffect(() => {
        fetchApplications();
    }, []);

    const fetchApplications = async () => {
        try {
            const token = localStorage.getItem('authToken'); // Retrieve the JWT token from local storage

            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/tutor-application/`, {
                headers: {
                    'Authorization': `Bearer ${token}` // Include the JWT token in the headers
                }
            });
            setApplications(response.data);
            setFilteredApplications(response.data);
        } catch (error) {
            console.error('Error fetching tutor applications:', error);
        }
    };


    useEffect(() => {
        const searchFiltered = applications.filter(application => 
            (application.first_name?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
            (application.last_name?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
            (application.phone?.toLowerCase() || '').includes(searchTerm.toLowerCase())
        );
        setFilteredApplications(searchFiltered);
        setCurrentPage(1); // Reset to first page on search
    }, [searchTerm, applications]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const indexOfLastApplication = currentPage * applicationsPerPage;
    const indexOfFirstApplication = indexOfLastApplication - applicationsPerPage;
    const currentApplications = filteredApplications.slice(indexOfFirstApplication, indexOfLastApplication);

    const totalPages = Math.ceil(filteredApplications.length / applicationsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleViewDetails = (applicationId) => {
        navigate(`/admin/tutor-applications/${applicationId}`);
    };

    return (
        <div className="tutor-applications">
            <h2 className="applications-title">Tutor Applications</h2>

            <div className="search-bar-container">
                <input 
                    type="text"
                    className="search-bar"
                    placeholder="Search by name, phone..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <FaSearch className="search-icon" />
            </div>

            <div className="applications-list">
                {currentApplications.map((application) => (
                    <div key={application.id} className="application-card">
                        <div className="application-header">
                            <p className="request-name"><strong>Applicant Name: </strong>{application.first_name} {application.last_name || ''}</p>
                        </div>
                        <div className="application-header">
                            <p className="request-phone"><strong>Phone: </strong>{application.phone || 'N/A'}</p>
                        </div>
                        <div className="request-actions">
                            <button className="hero-btn" onClick={() => handleViewDetails(application.id)}>View Details</button>
                        </div>
                    </div>
                ))}
            </div>

            <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index}
                        className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                        onClick={() => handlePageChange(index + 1)}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default TutorApplications;
