import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminDashboard.css';

function AdminDashboard({ firstName }) {
    const navigate = useNavigate();

    return (
        <div className="admin-dashboard">
            <header className="admin-dashboard-header">
                <h1 className="welcome-text">Welcome, {firstName}</h1>
            </header>

            <div className="admin-dashboard-content">
                <DashboardCard
                    title="Manage Tutors"
                    description="Add, view, and manage tutors in the system."
                    actions={[
                        { label: "Manage Tutors", onClick: () => navigate('/admin/tutors') },
                    ]}
                />

                <DashboardCard
                    title="Manage Users"
                    description="View, edit, and assign roles to users."
                    actions={[
                        { label: "View Users", onClick: () => navigate('/admin/users') },
                    ]}
                />

                <DashboardCard
                    title="Tutor Applications"
                    description="Review and manage tutor applications."
                    actions={[
                        { label: "View Applications", onClick: () => navigate('/admin/tutor-applications') },
                    ]}
                />

                <DashboardCard
                    title="Tutor Requests"
                    description="View and respond to tutor requests."
                    actions={[
                        { label: "View Requests", onClick: () => navigate('/admin/tutor-requests') },
                    ]}
                />
            </div>
        </div>
    );
}

function DashboardCard({ title, description, actions }) {
    return (
        <div className="dashboard-card">
            <h2>{title}</h2>
            <p>{description}</p>
            <div className="card-actions">
                {actions.map((action, index) => (
                    <button key={index} onClick={action.onClick} className="hero-btn">
                        {action.label}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default AdminDashboard;
