import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './ServicesSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboardTeacher, faBook, faLaptopCode, faUserGraduate } from '@fortawesome/free-solid-svg-icons';

const servicesData = [
    {
        icon: faChalkboardTeacher,
        title: 'Academic Tutoring',
        description: 'Offering a broad range of academic courses including Mathematics, General Science, Physics, Chemistry, and Languages (English, Amharic, Oromifa, Tigrigna).',
    },
    {
        icon: faBook,
        title: 'Ethiopian National Exam Preparation',
        description: 'Specialized preparation programs for Ethiopian National Exams to ensure students are thoroughly prepared for success.',
    },
    {
        icon: faLaptopCode,
        title: 'Interactive Online Sessions',
        description: 'Engage in dynamic, interactive online sessions that make learning fun and effective whenever you prefer to.',
    },
    {
        icon: faUserGraduate,
        title: 'Personalized Learning Plans',
        description: 'Receive tailored learning plans and one-on-one tutoring to specifically address your educational needs and goals.',
    },
];

const ServicesSection = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <section className="services-section" id="services">
            <div className="container">
                <h2 className="section-title" data-aos="fade-down">Our Services</h2>
                <div className="services-grid">
                    {servicesData.map((service, index) => (
                        <div
                            key={index}
                            className="service-card"
                            data-aos="fade-up"
                            data-aos-delay={`${index * 200}`}
                        >
                            <div className="icon-wrapper">
                                <FontAwesomeIcon icon={service.icon} className="service-icon" />
                            </div>
                            <h3 className="service-title">{service.title}</h3>
                            <p className="service-description">{service.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ServicesSection;
