import React, { useRef, useEffect } from 'react';
import './FeaturedTutors.css';

function FeaturedTutors({ tutors }) {
  const scrollContainerRef = useRef(null);
  const cardsRef = useRef([]); // Store references to each tutor card

  const scrollLeft = () => {
    scrollContainerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
  };

  const scrollRight = () => {
    scrollContainerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('tutor-card-animate');
            observer.unobserve(entry.target); // Trigger animation only once per card
          }
        });
      },
      { threshold: 0.1 } // When 10% of the card is in view
    );

    cardsRef.current.forEach((card) => {
      if (card) {
        observer.observe(card);
      }
    });

    return () => {
      cardsRef.current.forEach((card) => {
        if (card) {
          observer.unobserve(card);
        }
      });
    };
  }, []);

  return (
    <section className="featured-tutors-section">
      <h2 className="featured-tutors-title">Meet Our Top Tutors</h2>
      
      <div className="featured-tutors-scroll-container" ref={scrollContainerRef}>
        <div className="featured-tutors-cards-wrapper">
          {tutors.map((tutor, index) => (
            <div
              key={tutor.id}
              className="featured-tutor-card"
              ref={(el) => (cardsRef.current[index] = el)} // Store ref for each card
              style={{ transitionDelay: `${index * 150}ms` }} // Staggered delay
            >
              <div className="featured-tutor-image-container">
                <img src={tutor.photo} alt={tutor.name} className="featured-tutor-image" />
              </div>
              <h3>{tutor.name}</h3>
              <p><strong>Subject Expertise:</strong> {tutor.subject_expertise}</p>
              <p><strong>Qualifications:</strong> {tutor.qualifications}</p>
              <p><strong>Experience:</strong> {tutor.experience}</p>
              <p><strong>Location:</strong> {tutor.location}</p>
              <p><strong>Languages Spoken:</strong> {tutor.languages_spoken}</p>
              <p><strong>Currently Available:</strong> {tutor.available ? 'Yes' : 'No'}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default FeaturedTutors;
