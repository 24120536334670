import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import './EditTutor.css'; // Assuming you've saved the CSS below in this file

const EditTutor = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState({
        name: '',
        subject_expertise: '',
        qualifications: '',
        experience: '',
        location: '',
        languages_spoken: '',
        available: true,
        photo: null, // To hold the uploaded image
        existingPhoto: null, // To hold the URL of the existing image
        existingPhotoName: '' // To hold the name of the existing image
    });

    const [errors, setErrors] = useState({});
    const [showConfirmation, setShowConfirmation] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchTutorDetails();
    }, []);

    // Fetch current tutor details from the backend and set default values in the form
    const fetchTutorDetails = async () => {
        try {
            const token = localStorage.getItem('authToken'); // Retrieve the JWT token

            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/tutors/${id}/`, {
                headers: {
                    'Authorization': `Bearer ${token}` // Add the JWT token in the header
                }
            });

            setFormData({
                name: response.data.name,
                subject_expertise: response.data.subject_expertise,
                qualifications: response.data.qualifications,
                experience: response.data.experience,
                location: response.data.location,
                languages_spoken: response.data.languages_spoken,
                available: response.data.available,
                photo: null, // User will upload new photo if needed
                existingPhoto: response.data.photo ? response.data.photo : null, // Store URL if exists
                existingPhotoName: response.data.photo ? response.data.photo.split('/').pop() : '' // Extract the file name from the URL if exists
            });
        } catch (error) {
            console.error("Error fetching tutor details:", error);
        }
    };

    // Validate fields to ensure they are filled
    const validateField = (name, value) => {
        let error = '';

        if (typeof value === 'string' && !value.trim()) {
            error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
        } else if (!value && name === 'photo' && !formData.existingPhoto) { 
            // Only require profile picture if no existing photo
            error = 'Profile Picture is required';
        } else if (!value && name !== 'photo') {
            error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
        }

        return error;
    };

    // Handle input changes and validate fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        const fieldError = validateField(name, value);
        setErrors((prevErrors) => ({ ...prevErrors, [name]: fieldError }));
    };

    // Handle image upload separately
    const handleImageChange = (e) => {
        setFormData({ ...formData, photo: e.target.files[0] });
    };

    // Validate the form to ensure all fields are filled
    const validateForm = () => {
        const validationErrors = {};
        let isValid = true;

        Object.keys(formData).forEach(key => {
            const error = validateField(key, formData[key]);
            if (error) {
                validationErrors[key] = error;
                isValid = false;
            }
        });

        setErrors(validationErrors);
        return isValid;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const submitData = new FormData();
            submitData.append('name', formData.name);
            submitData.append('subject_expertise', formData.subject_expertise);
            submitData.append('qualifications', formData.qualifications);
            submitData.append('experience', formData.experience);
            submitData.append('location', formData.location);
            submitData.append('languages_spoken', formData.languages_spoken);
            submitData.append('available', formData.available);

            if (formData.photo) {
                submitData.append('photo', formData.photo);
            }

            const token = localStorage.getItem('authToken');
            console.log('Retrieved Token:', token);  // Debugging: Check if token is retrieved correctly

            if (!token) {
                setErrors({ form: 'Unauthorized: Please log in again.' });
                navigate('/SignInRegister');
                return;
            }

            try {
                const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/tutors/${id}/`.replace(/([^:]\/)\/+/g, "$1"); // Fix double slashes
                const response = await axios.put(apiUrl, submitData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}` // Include the JWT token
                    }
                });

                if (response.status === 200) {
                    setShowConfirmation(true);
                    setTimeout(() => {
                        setShowConfirmation(false);
                        navigate('/admin/tutors'); // Redirect to Manage Tutors page
                    }, 2000);
                } else {
                    setErrors({ form: 'Something went wrong. Please try again.' });
                }
            } catch (error) {
                console.error("Error updating tutor:", error);

                if (error.response && error.response.status === 401) {
                    setErrors({ form: 'Unauthorized: Please log in again.' });
                    localStorage.removeItem('authToken');
                    navigate('/SignInRegister');
                } else {
                    setErrors({ form: 'An error occurred. Please try again.' });
                }
            }
        }
    };

    return (
        <div className="edit-tutor-page">
            {showConfirmation && (
                <div className="confirmation-overlay">
                    <div className="confirmation-modal">
                        <div className="checkmark-circle">
                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle">
                                <path d="M9 11l3 3L22 4" />
                                <path d="M22 12a10 10 0 1 1-20 0 10 10 0 0 1 20 0z" />
                            </svg>
                        </div>
                        <h2 className="confirmation-title">Tutor Updated Successfully!</h2>
                    </div>
                </div>
            )}

            <div className="edit-tutor-form-container">
                <h2 className="edit-tutor-title">Edit Tutor</h2>
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="form-group">
                        <label htmlFor="name">Name *</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className={errors.name ? 'input-error' : ''}
                        />
                        {errors.name && <div className="form-error">{errors.name}</div>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="subject_expertise">Subject Expertise *</label>
                        <input
                            type="text"
                            id="subject_expertise"
                            name="subject_expertise"
                            value={formData.subject_expertise}
                            onChange={handleChange}
                            className={errors.subject_expertise ? 'input-error' : ''}
                        />
                        {errors.subject_expertise && <div className="form-error">{errors.subject_expertise}</div>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="qualifications">Qualifications *</label>
                        <input
                            type="text"
                            id="qualifications"
                            name="qualifications"
                            value={formData.qualifications}
                            onChange={handleChange}
                            className={errors.qualifications ? 'input-error' : ''}
                        />
                        {errors.qualifications && <div className="form-error">{errors.qualifications}</div>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="experience">Experience *</label>
                        <input
                            type="text"
                            id="experience"
                            name="experience"
                            value={formData.experience}
                            onChange={handleChange}
                            className={errors.experience ? 'input-error' : ''}
                        />
                        {errors.experience && <div className="form-error">{errors.experience}</div>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="location">Location *</label>
                        <input
                            type="text"
                            id="location"
                            name="location"
                            value={formData.location}
                            onChange={handleChange}
                            className={errors.location ? 'input-error' : ''}
                        />
                        {errors.location && <div className="form-error">{errors.location}</div>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="languages_spoken">Languages Spoken *</label>
                        <input
                            type="text"
                            id="languages_spoken"
                            name="languages_spoken"
                            value={formData.languages_spoken}
                            onChange={handleChange}
                            className={errors.languages_spoken ? 'input-error' : ''}
                        />
                        {errors.languages_spoken && <div className="form-error">{errors.languages_spoken}</div>}
                    </div>

                    {/* Add the image upload field */}
                    <div className="form-group">
                        <label htmlFor="photo">Profile Picture {formData.existingPhoto ? "(optional)" : "(required)"}</label>
                        <div>
                            {/* Show the name of the existing image if available */}
                            {formData.existingPhotoName && !formData.photo && (
                                <span>Current file: {formData.existingPhotoName}</span>
                            )}
                        </div>
                        <input
                            type="file"
                            id="photo"
                            name="photo"
                            accept="image/*"
                            onChange={handleImageChange}
                            className="image-upload"
                        />
                        {!formData.existingPhoto && errors.photo && <div className="form-error">{errors.photo}</div>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="available">Available *</label>
                        <select
                            id="available"
                            name="available"
                            value={formData.available}
                            onChange={handleChange}
                            className={errors.available ? 'input-error' : ''}
                        >
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>
                        {errors.available && <div className="form-error">{errors.available}</div>}
                    </div>

                    <button type="submit" className="submit-btn">Update Tutor</button>
                    {errors.form && <div className="form-error">{errors.form}</div>}
                </form>
            </div>
        </div>
    );
};

export default EditTutor;
