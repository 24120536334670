import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/common/Navbar';
import Footer from './components/common/Footer';
import Homepage from './components/homepage/Homepage';
import AboutSection from './components/homepage/AboutSection';
import ContactForm from './components/contact/ContactForm';
import NotFound from './components/NotFound';
import BecomeTutor from './components/BecomeTutor/BecomeTutor';
import FindTutor from './components/FindTutor/FindTutor';
import PricingSection from './components/PricingSection/PricingSection';
import SignInRegister from './components/auth/SignInRegister';
import Dashboard from './components/dashboard/Dashboard/Dashboard';
import ManageTutors from './components/dashboard/AdminDashboard/ManageTutors';
import AddTutor from './components/dashboard/AdminDashboard/AddTutor';
import AddUser from './components/dashboard/AdminDashboard/AddUser';
import EditTutor from './components/dashboard/AdminDashboard/EditTutor';
import AdminDashboard from './components/dashboard/AdminDashboard/AdminDashboard';
import ManageUsers from './components/dashboard/AdminDashboard/ManageUsers'; 
import TutorApplications from './components/dashboard/AdminDashboard/TutorApplications'; 
import TutorApplicationDetail from './components/dashboard/AdminDashboard/TutorApplicationDetail'; 
import TutorRequestDetail from './components/dashboard/AdminDashboard/TutorRequestDetail';
import TutorRequest from './components/dashboard/AdminDashboard/TutorRequest';
import DashboardWithErrorBoundary from './components/ErrorBoundary';
import ResetPassword from '../src/components/auth/ResetPassword';
import ResetPasswordConfirm from '../src/components/auth/ResetPasswordConfirm';
import AdminRoute from '../src/components/auth/AdminRoute'; // Import the AdminRoute component

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('authToken'));

    const handleLoginChange = (status) => {
        setIsLoggedIn(status);
    };

    const handleLogout = () => {
        // Remove auth token or perform any other cleanup
        localStorage.removeItem('authToken');
        localStorage.removeItem('userRole'); // Remove the user role
        setIsLoggedIn(false);
    };

    return (
        <Router>
            <div className="page-container">
                <Navbar isLoggedIn={isLoggedIn} onLogout={handleLogout} />
                <div className="content-wrapper">
                    <Routes>
                        <Route path="/" element={<Homepage />} />
                        <Route
                            path="/dashboard"
                            element={isLoggedIn ? <Dashboard /> : <Navigate to="/SignInRegister" />} 
                        />
                        <Route path="/about" element={<AboutSection />} />
                        <Route path="/contact" element={<ContactForm />} />
                        <Route path="/PricingSection" element={<PricingSection/>} />
                        <Route path="/FindTutor" element={<FindTutor />} />
                        <Route path="/BecomeTutor" element={<BecomeTutor />} />
                        <Route
                            path="/SignInRegister"
                            element={isLoggedIn ? <Navigate to="/" /> : <SignInRegister isLoggedIn={isLoggedIn} onLoginChange={handleLoginChange} />} 
                        />
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route path="/reset-password/:uidb64/:token" element={<ResetPasswordConfirm />} />
                        <Route path="*" element={<NotFound />} />

                        {/* Admin Routes */}
                        <Route path="/admin" element={<AdminRoute element={AdminDashboard} />} />
                        <Route path="/admin/tutors" element={<AdminRoute element={ManageTutors} />} />
                        <Route path="/admin/tutors/add" element={<AdminRoute element={AddTutor} />} />
                        <Route path="/admin/tutors/edit/:id" element={<AdminRoute element={EditTutor} />} />
                        <Route path="/admin/users" element={<AdminRoute element={ManageUsers} />} />
                        <Route path="/admin/users/add" element={<AdminRoute element={AddUser} />} />
                        <Route path="/admin/tutor-applications" element={<AdminRoute element={TutorApplications} />} />
                        <Route path="/admin/tutor-applications/:id" element={<AdminRoute element={TutorApplicationDetail} />} />
                        <Route path="/admin/tutor-requests/:id" element={<AdminRoute element={TutorRequestDetail} />} /> 
                        <Route path="/admin/tutor-requests" element={<AdminRoute element={TutorRequest} />} /> 

                        <Route path="/dashboard" element={<DashboardWithErrorBoundary />} />
                    </Routes>
                </div>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
