import React from 'react';
/* import './StepProgress.css';*/

function StepProgress({ currentStep }) {
    const steps = [
        "Basic Information",
        "Educational Background",
        "Document Upload",
        "Review & Submit"
    ];

    return (
        <div className="step-progress">
            {steps.map((step, index) => (
                <div
                    key={index}
                    className={`step-item ${index < currentStep ? 'completed' : ''} ${index === currentStep ? 'current' : ''}`}
                >
                    <div className="step-number">{index + 1}</div>
                    <div className="step-label">{step}</div>
                </div>
            ))}
        </div>
    );
}

export default StepProgress;
