import React, { useState, useEffect } from 'react';
import HeroSection from './HeroSection';
import AboutSection from './AboutSection';
import FeaturedTutors from './FeaturedTutors';
import ServicesSection from './ServicesSection';
import TestimonialSection from './TestimonialSection';
import FaqSection from './FaqSection';
import SocialMediaLinks from './SocailMediaLinks';
import ContactForm from '../contact/ContactForm';
import axios from 'axios';


function Homepage() {
    const [tutors, setTutors] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/tutors/`)
            .then(response => {
                setTutors(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error("There was an error fetching the tutors!", error);
                setLoading(false);
            });
    }, []);


    return (
        <div>
            <HeroSection />
            <AboutSection />
            <ServicesSection />
            <FeaturedTutors tutors={tutors} /> {/* Pass the fetched tutors data here */}
            <TestimonialSection />
            <div id="contact-form-section">
                <ContactForm />
            </div>
            <FaqSection />
            <SocialMediaLinks />
        </div>
    );
}

export default Homepage;
