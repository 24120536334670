import React from 'react';
import './TutorDashboard.css';

function TutorDashboard({ firstName }) {
    return (
        <div className="dashboard-container">
            <h1 className="dashboard-welcome">
                Welcome to your Dashboard, {firstName}!
            </h1>
            <div className="dashboard-content">
                <p className="dashboard-message">
                   In the future, you will be able to manage your tutoring sessions, view applications, and communicate with parents and students.  We are working hard to bring these features to you soon.
                </p>
            </div>
        </div>
    );
}

export default TutorDashboard;
