import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './AddUser.css';
import {
    validateEmail,
    validateRegisterPassword,
    validatePassword2,
    validatePhoneNumber,
    validateFirstName,
    validateLastName,
} from './validate';

const AddUser = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [role, setRole] = useState('parent');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [errors, setErrors] = useState({});
    const [showConfirmation, setShowConfirmation] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (e, fieldName) => {
        const value = e.target.value;
        let error = '';

        switch (fieldName) {
            case 'firstName':
                setFirstName(value);
                error = validateFirstName(value);
                break;
            case 'lastName':
                setLastName(value);
                error = validateLastName(value);
                break;
            case 'email':
                setEmail(value);
                error = validateEmail(value);
                break;
            case 'phoneNumber':
                setPhoneNumber(value);
                error = validatePhoneNumber(value);
                break;
            case 'password':
                setPassword(value);
                error = validateRegisterPassword(value);
                break;
            case 'password2':
                setPassword2(value);
                error = validatePassword2(password, value);
                break;
            case 'role':
                setRole(value);
                break;
            default:
                break;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: error,
        }));
    };

    const validateForm = () => {
        const formErrors = {
            firstName: validateFirstName(firstName),
            lastName: validateLastName(lastName),
            email: validateEmail(email),
            phoneNumber: validatePhoneNumber(phoneNumber),
            password: validateRegisterPassword(password),
            password2: validatePassword2(password, password2),
        };

        setErrors(formErrors);

        return Object.values(formErrors).every((error) => error === '');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            const token = localStorage.getItem('authToken'); // Retrieve the JWT token

            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/register/`, {
                first_name: firstName,
                last_name: lastName,
                email,
                phone_number: phoneNumber,
                role,
                password,
                password2,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}` // Attach the JWT token in the headers
                }
            });

            if (response.status === 201) {
                setShowConfirmation(true);

                setTimeout(() => {
                    setShowConfirmation(false);
                    navigate('/admin/users'); // Redirect to manage users page after successful creation
                }, 2000);
            }
        } catch (error) {
            console.error("Error adding user:", error);
            setErrors({ form: 'Failed to add user. Please check the details and try again.' });
        }
    };

    return (
        <div className="add-user-page">
            {showConfirmation && (
                <div className="confirmation-overlay">
                    <div className="confirmation-modal">
                        <div className="checkmark-circle">
                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle">
                                <path d="M9 11l3 3L22 4" />
                                <path d="M22 12a10 10 0 1 1-20 0 10 10 0 0 1 20 0z" />
                            </svg>
                        </div>
                        <h2 className="confirmation-title">User Added Successfully!</h2>
                    </div>
                </div>
            )}
            <div className="add-user-form-container">
                <h2 className="add-user-title">Add a New User</h2>
                <form onSubmit={handleSubmit}>
                    {errors.form && <div className="form-error">{errors.form}</div>}
                    <div className="form-group">
                        <label htmlFor="firstName">First Name *</label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={firstName}
                            onChange={(e) => handleInputChange(e, 'firstName')}
                            className={errors.firstName ? 'input-error' : ''}
                        />
                        {errors.firstName && <div className="form-error">{errors.firstName}</div>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="lastName">Last Name *</label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={lastName}
                            onChange={(e) => handleInputChange(e, 'lastName')}
                            className={errors.lastName ? 'input-error' : ''}
                        />
                        {errors.lastName && <div className="form-error">{errors.lastName}</div>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email *</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => handleInputChange(e, 'email')}
                            className={errors.email ? 'input-error' : ''}
                        />
                        {errors.email && <div className="form-error">{errors.email}</div>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="phoneNumber">Phone Number *</label>
                        <input
                            type="text"
                            id="phoneNumber"
                            name="phoneNumber"
                            value={phoneNumber}
                            onChange={(e) => handleInputChange(e, 'phoneNumber')}
                            className={errors.phoneNumber ? 'input-error' : ''}
                        />
                        {errors.phoneNumber && <div className="form-error">{errors.phoneNumber}</div>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password *</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={(e) => handleInputChange(e, 'password')}
                            className={errors.password ? 'input-error' : ''}
                        />
                        {errors.password && <div className="form-error">{errors.password}</div>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="password2">Confirm Password *</label>
                        <input
                            type="password"
                            id="password2"
                            name="password2"
                            value={password2}
                            onChange={(e) => handleInputChange(e, 'password2')}
                            className={errors.password2 ? 'input-error' : ''}
                        />
                        {errors.password2 && <div className="form-error">{errors.password2}</div>}
                    </div>
                    <div className="form-group">
                        <label>Role *</label>
                        <div className="role-selection">
                            <label className="role-option">
                                <input
                                    type="radio"
                                    name="role"
                                    value="parent"
                                    checked={role === 'parent'}
                                    onChange={(e) => handleInputChange(e, 'role')}
                                />
                                Parent
                            </label>
                            <label className="role-option">
                                <input
                                    type="radio"
                                    name="role"
                                    value="tutor"
                                    checked={role === 'tutor'}
                                    onChange={(e) => handleInputChange(e, 'role')}
                                />
                                Tutor
                            </label>
                        </div>
                    </div>
                    <button type="submit" className="submit-btn">Add User</button>
                </form>
            </div>
        </div>
    );
};

export default AddUser;
