import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ManageUsers.css';
import placeholderImage from '../../images/person.png';
import { FaSearch } from 'react-icons/fa'; // Import FaSearch from react-icons

const ManageUsers = () => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [newRole, setNewRole] = useState('');
    const [showRoleSelection, setShowRoleSelection] = useState(false);
    const [showConfirmationPrompt, setShowConfirmationPrompt] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 6;

    const navigate = useNavigate();

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        const searchFiltered = users.filter(user =>
            user.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email?.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredUsers(searchFiltered);
        setCurrentPage(1);
    }, [searchTerm, users]);

    const fetchUsers = async () => {
        try {
            const token = localStorage.getItem('authToken'); // Use 'authToken' here

            if (!token) {
                console.log('No token found. Please log in.');
                return;
            }

            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/`, {
                headers: {
                    'Authorization': `Bearer ${token}` // Add the token to the request header
                }
            });

            setUsers(response.data);
            setFilteredUsers(response.data);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };



    const handleRoleButtonClick = (userId, currentRole) => {
        setSelectedUser(userId);
        setNewRole(currentRole);
        setShowRoleSelection(true);
    };

    const handleRoleChange = (e) => {
        setNewRole(e.target.value);
    };

    const confirmRoleChange = async () => {
        console.log('Yes button clicked, proceeding with role change...'); // Debug line
    
        if (!selectedUser) {
            console.error('No user selected');
            return;
        }
    
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/user-role/`, {
                user_id: selectedUser,
                role: newRole
            });
            console.log('Response from backend:', response.data); // Debug line
            fetchUsers(); // Refresh the user list after role change
            setShowConfirmationPrompt(false); // Close the modal
            setShowRoleSelection(false); // Close role selection
        } catch (error) {
            console.error("Error updating user role:", error);
        }
    };
    
    const handleCancel = () => {
        setShowConfirmationPrompt(false);
        setShowRoleSelection(false);
        setSelectedUser(null);
        setNewRole('');
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleConfirmRoleChange = () => {
        if (!newRole) {
            console.error('No new role selected');
            return;
        }
        setShowRoleSelection(false);
        setShowConfirmationPrompt(true);
    };

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const navigateToAddUser = () => {
        navigate('/admin/users/add');
    };

    return (
        <div className="manage-users">
            <div className="manage-users-header">
                <h2 className="manage-users-title">Manage Users</h2>
                <button className="hero-btn add-user-btn" onClick={navigateToAddUser}>Add User</button>
            </div>

            <div className="search-bar-container">
                <input 
                    type="text"
                    className="search-bar"
                    placeholder="Search by name or email..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <FaSearch className="search-icon" />
            </div>

            <div className="user-list">
                {currentUsers.length ? (
                    currentUsers.map((user) => (
                        <div key={user.id} className="user-card">
                            <div className="user-card-body">
                                <div className="user-card-header">
                                    <img
                                        src={placeholderImage}
                                        alt={`${user.first_name}`}
                                        className="user-card-img"
                                    />
                                    <h3 className="user-name">{user.first_name} {user.last_name}</h3>
                                </div>
                                <p className="user-email"><strong>Email:</strong> {user.email}</p>
                                <p className="user-phone"><strong>Phone:</strong> {user.phone_number || 'N/A'}</p>
                                <p className="user-role"><strong>Role:</strong> {user.role}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No users found.</p>
                )}
            </div>

            <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index}
                        className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                        onClick={() => handlePageChange(index + 1)}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>


        </div>
    );
};

export default ManageUsers;
