import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './TutorApplicationDetail.css';

function TutorApplicationDetail() {
    const { id } = useParams();
    const [application, setApplication] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchApplication = async () => {
            try {
                const token = localStorage.getItem('authToken'); // Retrieve the JWT token from local storage

                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/tutor-application/${id}/`, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Include the JWT token in the headers
                    }
                });
                setApplication(response.data);
            } catch (error) {
                setError('Failed to load application details. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchApplication();
    }, [id]);


    const handleBackClick = () => {
        navigate('/admin/tutor-applications');
    };

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    return (
        <div className="tutor-application-detail">
            <div className="application-header">
                <h2>Application Details</h2>
                <button className="hero-btn back-btn" onClick={handleBackClick}>
                    Back to Applications
                </button>
            </div>
            <div className="application-content">
                <div className="application-section">
                    <h3>Basic Information</h3>
                    <p><strong>First Name:</strong> {application.first_name}</p>
                    <p><strong>Last Name:</strong> {application.last_name}</p>
                    <p><strong>Email:</strong> {application.email}</p>
                    <p><strong>Phone:</strong> {application.phone}</p>
                    <p><strong>Address:</strong> {application.address}</p>
                </div>
                <div className="application-section">
                    <h3>Education & Experience</h3>
                    <p><strong>Education:</strong> {application.education}</p>
                    <p><strong>Experience:</strong> {application.experience || 'No experience provided'}</p>
                </div>
                <div className="application-section">
                    <h3>Documents</h3>
                    <p><strong>Resume:</strong> <a href={application.resume} target="_blank" rel="noopener noreferrer">View Resume</a></p>
                    <p><strong>ID Document:</strong> <a href={application.id_document} target="_blank" rel="noopener noreferrer">View ID Document</a></p>
                </div>
            </div>
        </div>
    );
}

export default TutorApplicationDetail;
