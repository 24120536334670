import React from 'react';
import './TutorCard.css';

function TutorCard({ tutor }) {
    if (!tutor) {
        return <div className="tutor-profile-card tutor-profile-empty">No tutor data available</div>;
    }

    return (
        <div className="tutor-profile-card">
            <div className="tutor-profile-header">
                {/* Display tutor's photo */}
                {tutor.photo && (
                    <img 
                        src={tutor.photo} 
                        alt={tutor.name} 
                        className="tutor-profile-image"
                    />
                )}

                {/* Tutor's name */}
                <h3 className="tutor-profile-name">{tutor.name}</h3>
            </div>

            {/* Tutor's subject expertise */}
            <p className="tutor-profile-subject">Subject: {tutor.subject_expertise}</p>

            {/* Tutor's qualifications */}
            <p className="tutor-profile-qualifications">Qualifications: {tutor.qualifications || 'Not provided'}</p>

            {/* Tutor's experience */}
            <p className="tutor-profile-experience">Experience: {tutor.experience || 'Not provided'}</p>

            {/* Tutor's location */}
            <p className="tutor-profile-location">Location: {tutor.location || 'Not provided'}</p>

            {/* Tutor's languages spoken */}
            <p className="tutor-profile-languages">Languages: {tutor.languages_spoken || 'Not specified'}</p>

            {/* Availability */}
            <p className="tutor-profile-availability">Available: {tutor.available ? 'Yes' : 'No'}</p>
        </div>
    );
}

export default TutorCard;
