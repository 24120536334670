import React from 'react';
import './ParentDashboard.css';

function ParentDashboard({ firstName }) {
    return (
        <div className="dashboard-container">
            <h1 className="dashboard-welcome">
                Welcome to your Dashboard, {firstName}!
            </h1>
            <div className="dashboard-content">
                <p className="dashboard-message">
                    In the future, you will be able to rate your tutors, track your child's progress, and access financial information. 
                    We are working hard to bring these features to you soon.
                </p>
            </div>
        </div>
    );
}

export default ParentDashboard;
