import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './TutorRequestDetail.css';

const TutorRequestDetail = () => {
    const { id } = useParams();
    const [request, setRequest] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchRequestDetails = async () => {
            try {
                const token = localStorage.getItem('authToken'); // Retrieve the JWT token from localStorage

                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/tutor-requests/${id}/`, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Include the JWT token in the headers
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setRequest(data);
            } catch (error) {
                console.error('Error fetching request details:', error);
                setRequest({ error: 'Failed to load data' });
            }
        };

        fetchRequestDetails();
    }, [id]);


    const handleBack = () => {
        navigate('/admin/tutor-requests');
    };

    if (!request) {
        return <div>Loading...</div>;
    }

    if (request.error) {
        return (
            <div className="tutor-request-detail">
                <h2 className="detail-title">Error Loading Data</h2>
                <p>{request.error}</p>
                <button className="back-btn" onClick={handleBack}>Back to Requests</button>
            </div>
        );
    }

    return (
        <div className="tutor-request-detail">
            <h2 className="detail-title">Request Details</h2>
            <div className="detail-section">
                <p><strong>Name:</strong> {request.first_name} {request.last_name}</p>
                <p><strong>Email:</strong> {request.email || 'N/A'}</p>
                <p><strong>Phone:</strong> {request.phone_number || 'N/A'}</p>
                <p><strong>Address:</strong> {request.address || 'N/A'}</p>
                <p><strong>Message:</strong> {request.message || 'N/A'}</p>
                <p><strong>Requested At:</strong> {new Date(request.requested_at).toLocaleString()}</p>
            </div>
            <button className="back-btn" onClick={handleBack}>Back to Requests</button>
        </div>
    );
};

export default TutorRequestDetail;
