import React from 'react';
import './PricingSection.css';
import { FaCheckCircle, FaBook, FaGraduationCap, FaChalkboardTeacher, FaUserCheck, FaMedal, FaHeadset, FaSchool, FaUniversity } from 'react-icons/fa'; 

const PricingSection = () => {
    const handleCallClick = () => {
        window.location.href = 'tel:+251911148819'; // Replace with your actual phone number
    };

    return (
        <section className="pricing-section">
            <h2 className="pricing-title">Our Pricing</h2>
            <p className="pricing-description">Choose a plan that suits your needs.</p>
            <div className="pricing-cards-container">
                {/* Silver Package Card */}
                <div className="pricing-card">
                    <div className="pricing-card-header">
                        <h3>Silver Package</h3>
                        <p className="price">250 Birr/hr</p>
                    </div>
                    <div className="pricing-card-body">
                        <p className="pricing-info">
                           Tutors are Current University Students
                        </p>
                        <ul className="pricing-features">
                            <li><FaSchool className="icon" /> Kindergarten to Grade 4</li>
                            <li><FaBook className="icon" /> Interactive learning sessions</li>
                            <li><FaChalkboardTeacher className="icon" /> Engaging lessons</li>
                            <li><FaCheckCircle className="icon" /> Continuous support</li>
                            <li><FaGraduationCap className="icon" /> Regular progress assessments</li>
                            <li><FaUserCheck className="icon" /> Individualized attention and support</li>
                            <li><FaHeadset className="icon" /> Parental guidance and support sessions</li>
                        </ul>
                    </div>
                    <div className="pricing-card-footer">
                        <button className="call-us-btn" onClick={handleCallClick}>Call Us</button>
                    </div>
                </div>

                {/* Gold Package Card */}
                <div className="pricing-card">
                    <div className="pricing-card-header">
                        <h3>Gold Package</h3>
                        <p className="price">300 Birr/hr</p>
                    </div>
                    <div className="pricing-card-body">
                        <p className="pricing-info">
                            Tutors are Degree Holders
                        </p>
                        <ul className="pricing-features">
                            <li><FaSchool className="icon" /> Elementary Students 1-8</li>
                            <li><FaBook className="icon" /> In-depth subject tutoring</li>
                            <li><FaChalkboardTeacher className="icon" /> Comprehensive test preparation</li>
                            <li><FaGraduationCap className="icon" /> National Exam preparation</li>
                            <li><FaCheckCircle className="icon" /> National Exam mock problems</li>
                            <li><FaUserCheck className="icon" /> Parental guidance and support sessions</li>
                            <li><FaGraduationCap className="icon" /> One-on-one mentoring sessions</li>
                        </ul>
                    </div>
                    <div className="pricing-card-footer">
                        <button className="call-us-btn" onClick={handleCallClick}>Call Us</button>
                    </div>
                </div>

                {/* Platinum Package Card */}
                <div className="pricing-card">
                    <div className="pricing-card-header">
                        <h3>Platinum Package</h3>
                        <p className="price">350 Birr/hr</p>
                    </div>
                    <div className="pricing-card-body">
                        <p className="pricing-info">
                            Tutors are Master's / Double Degree holders or Teachers
                        </p>
                        <ul className="pricing-features">
                            <li><FaUniversity className="icon" /> High School Students 9-12</li>
                            <li><FaBook className="icon" /> Advanced subject tutoring</li>
                            <li><FaChalkboardTeacher className="icon" /> Specialized exam preparation</li>
                            <li><FaGraduationCap className="icon" /> National Exam and University Entrance Prep</li>
                            <li><FaCheckCircle className="icon" /> Mock exams and performance tracking</li>
                            <li><FaMedal className="icon" /> Career and academic counseling</li>
                            <li><FaUserCheck className="icon" /> Personalized mentoring and coaching</li>
                            <li><FaHeadset className="icon" /> Parental and student support</li>
                        </ul>
                    </div>
                    <div className="pricing-card-footer">
                        <button className="call-us-btn" onClick={handleCallClick}>Call Us</button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PricingSection;
