import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './AboutSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faChalkboardTeacher, faUserGraduate, faHandsHelping } from '@fortawesome/free-solid-svg-icons';

function AboutSection() {
    useEffect(() => {
        AOS.init({ duration: 1000, easing: 'ease-in-out', once: true });
    }, []);

    return (
        <section className="about-section" id="about">
            <div className="container">
                <div className="about-content">
                    <div className="about-text" data-aos="fade-right">
                        <h2 className="about-title">
                             About Us
                        </h2>
                        <p className="about-paragraph">
                            Nolawi Tutorial Service is dedicated to helping students achieve academic excellence through tailored learning experiences. 
                            Our expert tutors work closely with each student to understand their unique needs and goals, providing personalized guidance 
                            to improve grades, prepare for exams, and gain a deeper understanding of their subjects. We believe in a holistic approach 
                            to education that goes beyond the classroom, fostering not only academic success but also personal growth and confidence.
                        </p>
                    </div>
                    <div className="features" data-aos="fade-left">
                        <div className="feature-box" data-aos="zoom-in">
                            <div className="feature-icon">
                                <FontAwesomeIcon icon={faChalkboardTeacher} />
                            </div>
                            <h3>Expert Tutors</h3>
                            <p>Our tutors are leaders in their fields, providing deep insights and practical guidance to foster academic growth.</p>
                        </div>
                        <div className="feature-box" data-aos="zoom-in" data-aos-delay="200">
                            <div className="feature-icon">
                                <FontAwesomeIcon icon={faUserGraduate} />
                            </div>
                            <h3>Personalized Learning</h3>
                            <p>We craft customized teaching strategies that cater to each student's learning style and pace.</p>
                        </div>
                        <div className="feature-box" data-aos="zoom-in" data-aos-delay="400">
                            <div className="feature-icon">
                                <FontAwesomeIcon icon={faHandsHelping} />
                            </div>
                            <h3>Comprehensive Support</h3>
                            <p>We offer a robust support system to ensure your success in all academic endeavors.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutSection;
