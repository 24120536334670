import axiosInstance from '../../../components/axioInstance'
import React, { useEffect, useState } from 'react';
import TutorDashboard from '../TutorDashboard/TutorDashboard';
import ParentDashboard from '../ParentDashboard/ParentDashboard';
import AdminDashboard from '../AdminDashboard/AdminDashboard';
import ErrorBoundary from '../../ErrorBoundary'
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';

// Function to validate the token
const validateToken = () => {
    const token = localStorage.getItem('authToken');
    if (token) {
        const payload = JSON.parse(atob(token.split('.')[1]));  // Decode JWT token
        const currentTime = Date.now() / 1000;  // Current time in seconds

        if (payload.exp < currentTime) {  // Check if token is expired
            localStorage.removeItem('authToken');  // Token expired, remove it
            return false;  // Token is invalid
        }
        return true;  // Token is valid
    }
    return false;  // No token found
};

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function Dashboard() {
    const [userDetails, setUserDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();  // Initialize the navigate function

    useEffect(() => {
        // Validate the token before fetching user details
        const isValid = validateToken();
        if (!isValid) {
            console.log('Token invalid or expired, redirecting to login.');
            navigate('/SignInRegister');  // Redirect if token is invalid
            return;
        }

        const fetchUserDetails = async () => {
            const token = localStorage.getItem('authToken');
            if (!token) {
                console.log('No token found, redirecting to login.');
                navigate('/SignInRegister');
                return;
            }
    
            console.log('Token found:', token);
    
            try {
                const response = await axiosInstance.get('user-role/');
                console.log('API response:', response.data);
    
                if (response.data && response.data.role) {
                    setUserDetails({
                        ...response.data,
                        first_name: capitalizeFirstLetter(response.data.first_name)
                    });
                } else {
                    console.log('Invalid response data:', response.data);
                    setUserDetails(null);
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
    
                if (error.response) {
                    console.log('API Error Response:', error.response);
                    if (error.response.status === 401) {
                        console.log('Unauthorized, redirecting to login.');
                        localStorage.removeItem('authToken');
                        navigate('/SignInRegister');
                    }
                } else {
                    console.log('Error:', error.message);
                }
                setUserDetails(null);
            } finally {
                setLoading(false);
            }
        };
    
        fetchUserDetails();
    }, [navigate]);

    if (!userDetails) {
        return <div>Error loading dashboard.</div>;
    }

    switch (userDetails.role) {
        case 'tutor':
            return <TutorDashboard firstName={userDetails.first_name} />;
        case 'parent':
            return <ParentDashboard firstName={userDetails.first_name} />;
        case 'admin':
            return <AdminDashboard firstName={userDetails.first_name} />;
        default:
            return <div>Error: Unrecognized role.</div>;
    }
}

// Exporting the Dashboard wrapped in ErrorBoundary
export default function DashboardWithErrorBoundary() {
    return (
        <ErrorBoundary>
            <Dashboard />
        </ErrorBoundary>
    );
}
