import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './ManageTutors.css';
import placeholderImage from '../../images/person.png';
import { FaSearch } from 'react-icons/fa'; // Import the search icon

const ManageTutors = () => {
    const [tutors, setTutors] = useState([]);
    const [filteredTutors, setFilteredTutors] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showConfirmationPrompt, setShowConfirmationPrompt] = useState(false);
    const [tutorToDelete, setTutorToDelete] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const tutorsPerPage = 6;

    useEffect(() => {
        fetchTutors();
    }, []);

    useEffect(() => {
        const searchFiltered = tutors.filter(tutor =>
            tutor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            tutor.subject_expertise.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredTutors(searchFiltered);
        setCurrentPage(1); // Reset to first page on search
    }, [searchTerm, tutors]);

    const fetchTutors = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/tutors/`);
            setTutors(response.data);
            setFilteredTutors(response.data); // Initially set filteredTutors to the full list
        } catch (error) {
            console.error("Error fetching tutors:", error);
        }
    };

    const confirmDelete = (tutorId) => {
        setTutorToDelete(tutorId);
        setShowConfirmationPrompt(true);
    };

    const handleDelete = async () => {
        try {
            const token = localStorage.getItem('authToken'); // Retrieve the JWT token
            const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/tutors/${tutorToDelete}/`, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Pass the token in the Authorization header
                }
            });
            fetchTutors(); // Refresh the list after deletion
            setShowConfirmationPrompt(false);
            setShowDeletedConfirmation(true); // Show confirmation message after deletion
            setTimeout(() => {
                setShowDeletedConfirmation(false); // Hide after 3 seconds
            }, 3000);
        } catch (error) {
            console.error("Error deleting tutor:", error);
        }
    };
    


    const handleCancel = () => {
        setShowConfirmationPrompt(false);
        setTutorToDelete(null);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    // Pagination logic
    const indexOfLastTutor = currentPage * tutorsPerPage;
    const indexOfFirstTutor = indexOfLastTutor - tutorsPerPage;
    const currentTutors = filteredTutors.slice(indexOfFirstTutor, indexOfLastTutor);

    const totalPages = Math.ceil(filteredTutors.length / tutorsPerPage);
    const [showDeletedConfirmation, setShowDeletedConfirmation] = useState(false);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="manage-tutors-page">
            <div className="manage-tutors-header">
                <h2 className="manage-tutors-heading">Manage Tutors</h2>
                <Link to="/admin/tutors/add" className="add-tutor-button">Add Tutor</Link>
            </div>

            <div className="search-bar-wrapper">
                <input 
                    type="text"
                    className="tutors-search-bar"
                    placeholder="Search by name or subject expertise..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <FaSearch className="search-icon" />
            </div>

            <div className="tutors-list">
                {currentTutors.length ? (
                    currentTutors.map((tutor) => (
                        <div key={tutor.id} className="tutor-card-item">
                            <div className="tutor-card-content">
                                <div className="tutor-card-header">
                                    <img
                                        src={tutor.photo ? tutor.photo : placeholderImage}
                                        alt={`${tutor.name}`}
                                        className="tutor-card-image"
                                    />
                                    <h3 className="tutor-name">{tutor.name}</h3>
                                </div>
                                <p className="tutor-subject-expertise"><strong>Subject Expertise:</strong> {tutor.subject_expertise}</p>
                                <p className="tutor-qualifications"><strong>Qualifications:</strong> {tutor.qualifications}</p>
                                <p className="tutor-experience"><strong>Experience:</strong> {tutor.experience}</p>
                                <p className="tutor-location"><strong>Location:</strong> {tutor.location}</p>
                                <p className="tutor-languages"><strong>Languages Spoken:</strong> {tutor.languages_spoken}</p>
                                <p className="tutor-availability">
                                    <strong>Currently Available:</strong> {tutor.available ? 'Yes' : 'No'}
                                </p>
                            </div>
                            <div className="tutor-card-actions">
                                <Link to={`/admin/tutors/edit/${tutor.id}`} className="edit-tutor-btn">Edit</Link>
                                <button onClick={() => confirmDelete(tutor.id)} className="delete-tutor-btn">Delete</button>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No tutors found.</p>
                )}
            </div>

            <div className="pagination-controls">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index}
                        className={`pagination-btn ${currentPage === index + 1 ? 'active' : ''}`}
                        onClick={() => handlePageChange(index + 1)}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>

            {showConfirmationPrompt && (
                <div className="confirmation-dialog-overlay">
                    <div className="confirmation-dialog">
                        <h2 className="confirmation-dialog-title">Are you sure you want to delete this tutor?</h2>
                        <button onClick={handleDelete} className="confirm-delete-btn">Yes</button>
                        <button onClick={handleCancel} className="cancel-delete-btn">No</button>
                    </div>
                </div>
            )}
           {showDeletedConfirmation && (
                <div className="confirmation-dialog-overlay">
                    <div className="confirmation-dialog">
                        <div className="confirmation-checkmark-circle">
                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle">
                                <path d="M9 11l3 3L22 4" />
                                <path d="M22 12a10 10 0 1 1-20 0 10 10 0 0 1 20 0z" />
                            </svg>
                        </div>
                        <h2 className="confirmation-dialog-title">Tutor deleted successfully!</h2>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ManageTutors;
