import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './AddTutor.css'; // Assuming you've saved the CSS below in this file

const AddTutor = () => {
    const [formData, setFormData] = useState({
        name: '',
        subject_expertise: '',
        qualifications: '',
        experience: '',
        location: '',
        languages_spoken: '',
        available: true,
        photo: null // New field for the uploaded image
    });

    const [errors, setErrors] = useState({});
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [formError, setFormError] = useState(""); // To show a general form error
    const navigate = useNavigate();

    // Function to validate each field individually
    const validateField = (name, value) => {
        let error = '';

        // Apply trim only for string values
        if (typeof value === 'string' && !value.trim()) {
            error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
        } else if (!value && name === 'photo') {
            error = 'Profile Picture is required';
        } else if (!value && name !== 'photo') { // Handle non-string fields (like booleans)
            error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
        }

        return error;
    };


    // Handle field changes and validate them on change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        const fieldError = validateField(name, value);
        setErrors((prevErrors) => ({ ...prevErrors, [name]: fieldError }));
    };

    // Handle image upload separately
    const handleImageChange = (e) => {
        setFormData({ ...formData, photo: e.target.files[0] });
    };

    // Function to validate the entire form before submission
    const validateForm = () => {
        const validationErrors = {};
        let isValid = true;

        Object.keys(formData).forEach(key => {
            const error = validateField(key, formData[key]);
            if (error) {
                validationErrors[key] = error;
                isValid = false;
            }
        });

        setErrors(validationErrors);
        return isValid;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormError(""); // Reset form error message

        // Check if the form is valid before submitting
        if (validateForm()) {
            const submitData = new FormData();
            submitData.append('name', formData.name);
            submitData.append('subject_expertise', formData.subject_expertise);
            submitData.append('qualifications', formData.qualifications);
            submitData.append('experience', formData.experience);
            submitData.append('location', formData.location);
            submitData.append('languages_spoken', formData.languages_spoken);
            submitData.append('available', formData.available);
            if (formData.photo) {
                submitData.append('photo', formData.photo);
            }

            try {
                const token = localStorage.getItem('authToken'); // Retrieve the JWT token

                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/tutors/`, submitData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Important for file uploads
                        'Authorization': `Bearer ${token}` // Attach JWT token here
                    }
                });

                if (response.status === 201) {
                    setShowConfirmation(true);

                    setTimeout(() => {
                        setShowConfirmation(false);
                        setFormData({
                            name: '',
                            subject_expertise: '',
                            qualifications: '',
                            experience: '',
                            location: '',
                            languages_spoken: '',
                            available: true,
                            photo: null
                        });
                        navigate('/admin/tutors'); // Redirect to Manage Tutors page
                    }, 2000);
                } else {
                    setFormError('Something went wrong. Please try again.'); // Set a general form error
                }
            } catch (error) {
                console.error("Error adding tutor:", error);
                setFormError('An error occurred. Please try again.'); // Set a general form error
            }
        } else {
            setFormError('Please fill in all required fields.'); // Set the form error if validation fails
        }
    };

    return (
        <div className="add-tutor-page">
            {showConfirmation && (
                <div className="confirmation-overlay">
                    <div className="confirmation-modal">
                        <div className="checkmark-circle">
                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle">
                                <path d="M9 11l3 3L22 4" />
                                <path d="M22 12a10 10 0 1 1-20 0 10 10 0 0 1 20 0z" />
                            </svg>
                        </div>
                        <h2 className="confirmation-title">Tutor Added Successfully!</h2>
                    </div>
                </div>
            )}

            <div className="add-tutor-form-container">
                <h2 className="add-tutor-title">Add a New Tutor</h2>
                
                {formError && <div className="form-error">{formError}</div>} {/* Show form error */}

                <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="form-group">
                        <label htmlFor="name">Name *</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className={errors.name ? 'input-error' : ''}
                        />
                        {errors.name && <div className="form-error">{errors.name}</div>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="subject_expertise">Subject Expertise *</label>
                        <input
                            type="text"
                            id="subject_expertise"
                            name="subject_expertise"
                            value={formData.subject_expertise}
                            onChange={handleChange}
                            className={errors.subject_expertise ? 'input-error' : ''}
                        />
                        {errors.subject_expertise && <div className="form-error">{errors.subject_expertise}</div>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="qualifications">Qualifications *</label>
                        <input
                            type="text"
                            id="qualifications"
                            name="qualifications"
                            value={formData.qualifications}
                            onChange={handleChange}
                            className={errors.qualifications ? 'input-error' : ''}
                        />
                        {errors.qualifications && <div className="form-error">{errors.qualifications}</div>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="experience">Experience *</label>
                        <input
                            type="text"
                            id="experience"
                            name="experience"
                            value={formData.experience}
                            onChange={handleChange}
                            className={errors.experience ? 'input-error' : ''}
                        />
                        {errors.experience && <div className="form-error">{errors.experience}</div>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="location">Location *</label>
                        <input
                            type="text"
                            id="location"
                            name="location"
                            value={formData.location}
                            onChange={handleChange}
                            className={errors.location ? 'input-error' : ''}
                        />
                        {errors.location && <div className="form-error">{errors.location}</div>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="languages_spoken">Languages Spoken *</label>
                        <input
                            type="text"
                            id="languages_spoken"
                            name="languages_spoken"
                            value={formData.languages_spoken}
                            onChange={handleChange}
                            className={errors.languages_spoken ? 'input-error' : ''}
                        />
                        {errors.languages_spoken && <div className="form-error">{errors.languages_spoken}</div>}
                    </div>

                    {/* Add the image upload field */}
                    <div className="form-group">
                        <label htmlFor="photo">Profile Picture</label>
                        <input
                            type="file"
                            id="photo"
                            name="photo"
                            accept="image/*"
                            onChange={handleImageChange}
                            className="image-upload"
                        />
                        {errors.photo && <div className="form-error">{errors.photo}</div>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="available">Available *</label>
                        <select
                            id="available"
                            name="available"
                            value={formData.available}
                            onChange={handleChange}
                            className={errors.available ? 'input-error' : ''}
                        >
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>
                        {errors.available && <div className="form-error">{errors.available}</div>}
                    </div>

                    <button type="submit" className="submit-btn">Add Tutor</button>
                    {errors.form && <div className="form-error">{errors.form}</div>}
                </form>
            </div>
        </div>
    );
};

export default AddTutor;
