import React, { useState } from 'react';
import StepProgress from './StepProgress';
import './BecomeTutor.css';
import axios from 'axios';

function BecomeTutor() {
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        education: '',
        resume: null,
        idDocument: null,
        experience: '',
    });
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleNext = () => {
        const newErrors = {};
        if (currentStep === 0) {
            if (!formData.firstName) newErrors.firstName = 'First Name is required';
            if (!formData.lastName) newErrors.lastName = 'Last Name is required';
            if (!formData.email) newErrors.email = 'Email is required';
            if (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) newErrors.email = 'Invalid email address';
            if (!formData.phone) newErrors.phone = 'Phone Number is required';
            if (formData.phone && !/^[0-9\(\)\-\s]{10,15}$/.test(formData.phone)) newErrors.phone = 'Phone Number must contain only numbers, and be 10-15 digits';
            if (!formData.address) newErrors.address = 'Address is required';
        }
        if (currentStep === 1) {
            if (!formData.education) newErrors.education = 'Education level is required';
        }
        if (currentStep === 2) {
            if (!formData.resume) newErrors.resume = 'Resume is required';
            if (!formData.idDocument) newErrors.idDocument = 'ID Document is required';
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setErrors({});
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrev = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Perform validation on each change
        let newErrors = { ...errors };
        switch (name) {
            case 'firstName':
                newErrors.firstName = value.trim() ? '' : 'First Name is required';
                break;
            case 'lastName':
                newErrors.lastName = value.trim() ? '' : 'Last Name is required';
                break;
            case 'email':
                if (!value.trim()) {
                    newErrors.email = 'Email is required';
                } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                    newErrors.email = 'Invalid email address';
                } else {
                    newErrors.email = '';
                }
                break;
            case 'phone':
                if (!value.trim()) {
                    newErrors.phone = 'Phone Number is required';
                } else if (!/^[0-9\(\)\-\s]{10,15}$/.test(value)) {
                    newErrors.phone = 'Phone Number must contain only numbers, and be 10-15 digits';
                } else {
                    newErrors.phone = '';
                }
                break;
            case 'address':
                newErrors.address = value.trim() ? '' : 'Address is required';
                break;
            case 'education':
                newErrors.education = value.trim() ? '' : 'Education level is required';
                break;
            default:
                break;
        }

        setErrors(newErrors);
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.files[0]
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const formDataToSend = new FormData();
        formDataToSend.append('first_name', formData.firstName);
        formDataToSend.append('last_name', formData.lastName);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('phone', formData.phone);
        formDataToSend.append('address', formData.address);
        formDataToSend.append('education', formData.education);
        formDataToSend.append('experience', formData.experience);
        formDataToSend.append('resume', formData.resume);
        formDataToSend.append('id_document', formData.idDocument);
    
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/tutor-application/`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 201) {
                setIsSubmitted(true);
                setCurrentStep(0);
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    address: '',
                    education: '',
                    resume: null,
                    idDocument: null,
                    experience: '',
                });
            } else {
                console.error('Something went wrong while submitting the form');
            }
        } catch (error) {
            console.error('There was an error submitting the form:', error);
        }
    };

    return (
        <div className="become-tutor">
            <h2 className="tutor-title">Apply to Become a Tutor</h2>
            <StepProgress currentStep={currentStep} />

            {isSubmitted ? (
                <div className="confirmation-animation">
                    <div className="checkmark-circle">
                        <div className="checkmark"></div>
                    </div>
                    <p>Thank you! Your application has been submitted successfully.</p>
                </div>
            ) : (
                <form className="tutor-form">
                    {currentStep === 0 && (
                        <div className="form-step">
                            <h3>Basic Information</h3>
                            <input
                                type="text"
                                name="firstName"
                                placeholder="First Name *"
                                value={formData.firstName}
                                onChange={handleChange}
                                className={errors.firstName ? 'input-error' : ''}
                            />
                            {errors.firstName && <p className="error-text">{errors.firstName}</p>}
                            <input
                                type="text"
                                name="lastName"
                                placeholder="Last Name *"
                                value={formData.lastName}
                                onChange={handleChange}
                                className={errors.lastName ? 'input-error' : ''}
                            />
                            {errors.lastName && <p className="error-text">{errors.lastName}</p>}
                            <input
                                type="email"
                                name="email"
                                placeholder="Email Address *"
                                value={formData.email}
                                onChange={handleChange}
                                className={errors.email ? 'input-error' : ''}
                            />
                            {errors.email && <p className="error-text">{errors.email}</p>}
                            <input
                                type="text"
                                name="phone"
                                placeholder="Phone Number *"
                                value={formData.phone}
                                onChange={handleChange}
                                className={errors.phone ? 'input-error' : ''}
                            />
                            {errors.phone && <p className="error-text">{errors.phone}</p>}
                            <input
                                type="text"
                                name="address"
                                placeholder="Address *"
                                value={formData.address}
                                onChange={handleChange}
                                className={errors.address ? 'input-error' : ''}
                            />
                            {errors.address && <p className="error-text">{errors.address}</p>}
                        </div>
                    )}

                    {currentStep === 1 && (
                        <div className="form-step">
                            <h3>Educational Background *</h3>
                            <select
                                name="education"
                                value={formData.education}
                                onChange={handleChange}
                                className={errors.education ? 'input-error' : ''}
                            >
                                <option value="">Select your highest education level </option>
                                <option value="High School">High School</option>
                                <option value="Associate Degree">Associate Degree</option>
                                <option value="Bachelor's Degree">Bachelor's Degree</option>
                                <option value="Master's Degree">Master's Degree</option>
                                <option value="Doctorate">Doctorate</option>
                                <option value="Other">Other</option>
                            </select>
                            {errors.education && <p className="error-text">{errors.education}</p>}
                            
                            <h3>Tutoring Experience (Optional)</h3>
                            <textarea
                                name="experience"
                                placeholder="Describe your previous tutoring experience..."
                                value={formData.experience}
                                onChange={handleChange}
                                className="experience-textarea"
                            />
                        </div>
                    )}

                    {currentStep === 2 && (
                        <div className="form-step">
                            <h3>Document Upload</h3>
                            <label>Upload your Resume *</label>
                            <input
                                type="file"
                                name="resume"
                                onChange={handleFileChange}
                                className={errors.resume ? 'input-error' : ''}
                            />
                            {errors.resume && <p className="error-text">{errors.resume}</p>}
                            <label>Upload your ID Document (Passport | Drivers License | Govenment ID | student ID | others ) *</label>
                            <input
                                type="file"
                                name="idDocument"
                                onChange={handleFileChange}
                                className={errors.idDocument ? 'input-error' : ''}
                            />
                            {errors.idDocument && <p className="error-text">{errors.idDocument}</p>}
                        </div>
                    )}

                    {currentStep === 3 && (
                        <div className="form-step review-step">
                            <h3 className="review-title">Review Your Application</h3>
                            <div className="review-section">
                                <div className="review-item">
                                    <strong>First Name:</strong> {formData.firstName}
                                </div>
                                <div className="review-item">
                                    <strong>Last Name:</strong> {formData.lastName}
                                </div>
                                <div className="review-item">
                                    <strong>Email:</strong> {formData.email}
                                </div>
                                <div className="review-item">
                                    <strong>Phone:</strong> {formData.phone}
                                </div>
                                <div className="review-item">
                                    <strong>Address:</strong> {formData.address}
                                </div>
                                <div className="review-item">
                                    <strong>Education:</strong> {formData.education}
                                </div>
                                <div className="review-item">
                                    <strong>Experience:</strong> {formData.experience || 'No experience provided'}
                                </div>
                                <div className="review-item">
                                    <strong>Resume:</strong> {formData.resume ? formData.resume.name : 'Not Uploaded'}
                                </div>
                                <div className="review-item">
                                    <strong>ID Document:</strong> {formData.idDocument ? formData.idDocument.name : 'Not Uploaded'}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="form-navigation">
                        {currentStep > 0 && (
                            <button
                                type="button"
                                className="prev-btn"
                                onClick={handlePrev}
                            >
                                Previous
                            </button>
                        )}
                        {currentStep < 3 ? (
                            <button
                                type="button"
                                className="next-btn"
                                onClick={handleNext}
                            >
                                Next
                            </button>
                        ) : (
                            <button type="button" className="submit-btn" onClick={handleSubmit}>
                                Submit Application
                            </button>
                        )}
                    </div>
                </form>
            )}
        </div>
    );
}

export default BecomeTutor;
