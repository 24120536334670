import React, { useEffect, useRef, useState } from 'react';
import './FaqSection.css'; // Import your CSS file for styling

function FaqSection() {
    const [activeIndex, setActiveIndex] = useState(null);
    const [showAll, setShowAll] = useState(false);
    const faqItemsRef = useRef([]); // References to each FAQ item

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate'); // Add the animation class
                    }
                });
            },
            {
                threshold: 0.1, // Trigger when 10% of the item is in view
            }
        );

        faqItemsRef.current.forEach((item) => {
            if (item) {
                observer.observe(item);
            }
        });

        return () => {
            faqItemsRef.current.forEach((item) => {
                if (item) {
                    observer.unobserve(item);
                }
            });
        };
    }, []);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const faqs = [
        {
            question: "How does the home-to-home tutoring service work?",
            answer: "Once you request a tutor and sign up for our service, we will match you with a qualified tutor who will come to your home at a time that’s convenient for you. The tutor will provide personalized, one-on-one instruction based on your child’s needs and learning pace.",
        },
        {
            question: "What is expected of me as a parent/student?",
            answer: "Parents should provide a quiet learning environment, communicate goals to the tutor, and ensure the student is prepared. Students should engage actively in sessions, complete assignments, and provide feedback to the tutor.",
        },
        {
            question: "Why should I choose Nolawi Tutorial Service?",
            answer: "Nolawi Tutors offers personalized learning, qualified tutors, flexible scheduling, and a proven track record of student success. We prioritize providing comprehensive support to help students reach their full potential at affordable prices.",
        },
        {
            question: "What subjects do you offer tutoring in?",
            answer: "We offer tutoring services across a wide range of subjects, including Mathematics, Science, English, History, and more. We also provide specialized tutoring for the Ethiopia Nation Examination. Our tutors are experts in their respective fields and are equipped to help students from elementary school through high school.",
        },
        {
            question: "How do you select your tutors?",
            answer: "Our tutors go through a rigorous selection process that includes background checks, interviews, and subject-specific assessments. We ensure that all tutors are not only knowledgeable in their subjects but also skilled in teaching and mentoring students.",
        },
        {
            question: "Can I request a tutor with specific qualifications?",
            answer: "Absolutely! We understand that each student has unique needs. You can specify your preferences, such as the tutor's experience, teaching style, or educational background, and we will match you with the most suitable tutor available.",
        },
        {
            question: "What is the cost of your tutoring services?",
            answer: "Our pricing varies depending on the subject, tutor’s qualifications, and the number of sessions required. We offer competitive rates and flexible payment plans. Please visit the Pricing section or contact us for a personalized quote based on your specific needs.",
        },
        {
            question: "Do you offer special packages for tutoring multiple students?",
            answer: "Yes, we offer discounted packages for families or groups who need tutoring for two or more students. This allows you to share the cost while ensuring each student receives the personalized attention they need.",
        },
        {
            question: "What happens if I’m not satisfied with the tutor?",
            answer: "Your satisfaction is our priority. If you are not happy with your tutor for any reason, you can request a replacement. We will work with you to find a tutor who better suits your needs at no additional cost.",
        },
        {
            question: "How do you ensure the safety of students during home visits?",
            answer: "Safety is paramount to us. All our tutors undergo thorough background checks and are trained to maintain a professional and safe learning environment. However, we encourage parents to be present during sessions if possible.",
        },
        {
            question: "How flexible are the tutoring schedules?",
            answer: "We offer highly flexible scheduling options. Tutoring sessions can be arranged at times that are convenient for you, including evenings and weekends. You can also reschedule sessions with proper notice if needed.",
        },
        {
            question: "Do you offer online tutoring as well?",
            answer: "Yes, we offer both in-person and online tutoring services. If you prefer or need online sessions, our tutors are equipped to deliver high-quality, interactive tutoring through our online platform.",
        },
        {
            question: "Can I schedule a trial session before committing to a full package?",
            answer: "Yes, we offer a trial session at a reduced rate so you can experience our tutoring services firsthand. This allows you to assess the tutor's compatibility with your child before making a longer-term commitment.",
        },
    ];

    const itemsToShow = 6 ;

    return (
        <div className="faq-section">
            <h2 className="faq-title">Frequently Asked Questions</h2>
            <div className="faq-container">
                {(showAll ? faqs : faqs.slice(0, itemsToShow)).map((faq, index) => (
                    <div
                        key={index}
                        ref={(el) => faqItemsRef.current[index] = el}
                        className={`faq-item ${activeIndex === index ? 'active' : ''}`}
                        onClick={() => toggleFAQ(index)}
                    >
                        <div className="faq-question">
                            {faq.question}
                            <span className="faq-icon">{activeIndex === index ? '-' : '+'}</span>
                        </div>
                        <div className={`faq-answer ${activeIndex === index ? 'expand' : ''}`}>
                            {faq.answer}
                        </div>
                    </div>
                ))}

                {faqs.length > itemsToShow && (
                    <div
                        className="show-more"
                        onClick={toggleShowAll}
                    >
                        <div className="arrow-container">
                            <span className="arrow-text">{showAll ? 'Show Less' : 'Show More'}</span>
                            <div className={`arrow-icon ${showAll ? 'up' : ''}`}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-chevron-down"
                                >
                                    <polyline points="6 9 12 15 18 9"></polyline>
                                </svg>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default FaqSection;
