import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import './TutorRequest.css';

const TutorRequest = () => {
    const [requests, setRequests] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredRequests, setFilteredRequests] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const requestsPerPage = 6;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchRequests = async () => {
            try {
                const token = localStorage.getItem('authToken'); // Retrieve the JWT token from localStorage

                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/tutor-request/`, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Include the JWT token in the headers
                    }
                });

                const data = await response.json();
                setRequests(data);
                setFilteredRequests(data);
            } catch (error) {
                console.error('Error fetching tutor requests:', error);
            }
        };

        fetchRequests();
    }, []);


    useEffect(() => {
        const searchFiltered = requests.filter(request => 
            (request.first_name?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
            (request.last_name?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
            (request.email?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
            (request.phone_number?.toLowerCase() || '').includes(searchTerm.toLowerCase())
        );
        setFilteredRequests(searchFiltered);
        setCurrentPage(1); // Reset to first page on search
    }, [searchTerm, requests]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const indexOfLastRequest = currentPage * requestsPerPage;
    const indexOfFirstRequest = indexOfLastRequest - requestsPerPage;
    const currentRequests = filteredRequests.slice(indexOfFirstRequest, indexOfLastRequest);

    const totalPages = Math.ceil(filteredRequests.length / requestsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleViewDetails = (id) => {
        navigate(`/admin/tutor-requests/${id}`);
    };

    return (
        <div className="tutor-requests">
            <h2 className="requests-title">Tutor Requests</h2>

            <div className="search-bar-container">
                <input 
                    type="text"
                    className="search-bar"
                    placeholder="Search by name, email, or phone..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <FaSearch className="search-icon" />
            </div>

            <div className="requests-list">
                {currentRequests.map((request) => (
                    <div key={request.id} className="request-card">
                        <div className="request-header">
                            <h3 className="request-name"><strong>Requester Name:</strong> {request.first_name} {request.last_name || ''}</h3> {/* Updated Name Tag */}
                            <p className="request-email"><strong>Email:</strong> {request.email || 'N/A'}</p>
                            <p className="request-phone"><strong>Phone:</strong> {request.phone_number || 'N/A'}</p>
                            <p className="request-date"><strong>Requested At:</strong> {new Date(request.requested_at).toLocaleDateString()}</p> {/* Date */}
                        </div>
                        <div className="request-actions">
                            <button className="hero-btn" onClick={() => handleViewDetails(request.id)}>View Details</button>
                        </div>
                    </div>
                ))}
            </div>

            <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index}
                        className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                        onClick={() => handlePageChange(index + 1)}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default TutorRequest;
