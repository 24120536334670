import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './ContactForm.css';
import axios from 'axios';

export const validatePhoneNumber = (phoneNumber) => {
    const regex = /^\+?[0-9\(\)\-\s]{10,15}$/;

    if (!phoneNumber) {
        return 'Phone number is required.';
    }

    return regex.test(phoneNumber) ? '' : 'Phone number must be numeric, with an optional leading +, and between 10-15 digits. It may include spaces, dashes, and parentheses.';
};

function ContactForm() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        address: '',
        message: ''
    });
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
        AOS.init({
            duration: 10,
            easing: 'ease-in-out',
            once: true,
            mirror: false,
        });
    }, []);

    const validateField = (name, value) => {
        let error = '';

        switch (name) {
            case 'firstName':
                if (!value.trim()) error = 'First name is required';
                break;
            case 'lastName':
                if (!value.trim()) error = 'Last name is required';
                break;
            case 'email':
                if (value && !/\S+@\S+\.\S+/.test(value)) {
                    error = 'Email address is invalid';
                }
                break;
            case 'phoneNumber':
                error = validatePhoneNumber(value); // Custom phone number validation
                break;
            case 'address':
                if (!value.trim()) error = 'Address is required';
                break;
            default:
                break;
        }

        return error;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Validate the field and update the errors state in real-time
        const fieldError = validateField(name, value);
        setErrors((prevErrors) => ({ ...prevErrors, [name]: fieldError }));
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        const fieldError = validateField(name, value);
        setErrors((prevErrors) => ({ ...prevErrors, [name]: fieldError }));
    };

    const validateForm = () => {
        const validationErrors = {};
        let isValid = true; // Assume the form is valid unless proven otherwise
    
        Object.keys(formData).forEach(key => {
            const error = validateField(key, formData[key]); // Validate each field
            if (error) {
                validationErrors[key] = error; // If there's an error, add it to the error object
                isValid = false; // Set form as invalid
            }
        });
    
        setErrors(validationErrors); // Update the errors state
        return isValid; // Return the overall form validity
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) { // This function checks if all fields are valid
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/tutor-request/`, {
                    first_name: formData.firstName,
                    last_name: formData.lastName,
                    email: formData.email,
                    phone_number: formData.phoneNumber,
                    address: formData.address,
                    message: formData.message,
                });
                if (response.status === 201) {
                    setIsSubmitted(true);
                    setShowConfirmation(true); // Show the confirmation overlay
    
                    setTimeout(() => {
                        setShowConfirmation(false); // Hide the confirmation after a set time
                        setFormData({ // Reset form fields to empty after submission
                            firstName: '', lastName: '', email: '', phoneNumber: '', address: '', message: ''
                        });
                        setIsSubmitted(false); // Reset the submission state
                    }, 3000); // The time after which the confirmation and form reset happens
                } else {
                    setErrors({ form: 'Something went wrong. Please try again.' });
                }
            } catch (error) {
                console.error("There was an error submitting the tutor request:", error);
                setErrors({ form: 'An error occurred. Please try again.' });
            }
        }
    }
    
    return (
        <div className="contact-page">
            <div className="contact-info-container" data-aos="fade-right">
                <div className="contact-title">
                    <h1>Contact Us Today</h1>
                </div>
                <div className="contact-info">
                    <div className="contact-details">
                        <div className="contact-item" data-aos="fade-right" data-aos-delay="100">
                            <i className="fas fa-map-marker-alt icon-circle"></i>
                            <p>Tor Hayloch Square, Yoban Building,  3rd Floor, Addis Ababa, Ethiopia</p>
                        </div>
                        <div className="contact-item" data-aos="fade-right" data-aos-delay="200">
                            <i className="fas fa-phone-alt icon-circle"></i>
                            <p> (+251)9-11-14-88-19</p>
                        </div>
                        <div className="contact-item" data-aos="fade-right" data-aos-delay="200">
                            <i className="fas fa-phone-alt icon-circle"></i>
                            <p> (+251)9-45-85-45-75</p>
                        </div>
                        <div className="contact-item" data-aos="fade-right" data-aos-delay="300">
                            <i className="fas fa-envelope icon-circle"></i>
                            <p>Nolawitutorialservice@gmail.com</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-form-container" data-aos="fade-left">
                {showConfirmation && (
                    <div className="confirmation-overlay">
                        <div className="confirmation-modal">
                            <div className="checkmark-circle">
                                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle">
                                    <path d="M9 11l3 3L22 4" />
                                    <path d="M22 12a10 10 0 1 1-20 0 10 10 0 0 1 20 0z" />
                                </svg>
                            </div>
                            <h2 className="confirmation-title">Success!</h2>
                            <p className="confirmation-message">We have recieved your Tutor request, we will contact you soon!</p>
                        </div>
                    </div>
                )}
                <form onSubmit={handleSubmit}>
                    <div className="contact-title">
                        <h1>Request A Tutor</h1>
                    </div>
                    <div className="form-row">
                        <div className="form-group" data-aos="fade-up" data-aos-delay="100">
                            <label htmlFor="firstName">First name *</label>
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.firstName ? 'input-error' : ''}
                            />
                            {errors.firstName && <div className="form-error">{errors.firstName}</div>}
                        </div>
                        <div className="form-group" data-aos="fade-up" data-aos-delay="200">
                            <label htmlFor="lastName">Last name *</label>
                            <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.lastName ? 'input-error' : ''}
                            />
                            {errors.lastName && <div className="form-error">{errors.lastName}</div>}
                        </div>
                    </div>
                    <div className="form-group" data-aos="fade-up" data-aos-delay="300">
                        <label htmlFor="email">Email (optional)</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.email ? 'input-error' : ''}
                        />
                        {errors.email && <div className="form-error">{errors.email}</div>}
                    </div>
                    <div className="form-group" data-aos="fade-up" data-aos-delay="400">
                        <label htmlFor="phoneNumber">Phone number *</label>
                        <input
                            type="tel"
                            id="phoneNumber"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.phoneNumber ? 'input-error' : ''}
                        />
                        {errors.phoneNumber && <div className="form-error">{errors.phoneNumber}</div>}
                    </div>
                    <div className="form-group" data-aos="fade-up" data-aos-delay="500">
                        <label htmlFor="address">Address *</label>
                        <input
                            type="text"
                            id="address"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.address ? 'input-error' : ''}
                        />
                        {errors.address && <div className="form-error">{errors.address}</div>}
                    </div>
                    <div className="form-group" data-aos="fade-up" data-aos-delay="600">
                        <label htmlFor="message">Message (optional)</label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.message ? 'input-error' : ''}
                        ></textarea>
                        {errors.message && <div className="form-error">{errors.message}</div>}
                    </div>
                    <button type="submit" className="submit-btn" data-aos="fade-up" data-aos-delay="700">Request a Tutor</button>
                    {errors.form && <div className="form-error">{errors.form}</div>}
                </form>
            </div>
        </div>
    );
}

export default ContactForm;
