import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn, faTiktok, faTelegram } from '@fortawesome/free-brands-svg-icons';
import './SocialMediaLinks.css';

function SocialMediaLinks() {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            easing: 'ease-in-out', // Easing function for animations
            once: true, // Whether animation should happen only once
            mirror: false, // Whether elements should animate out while scrolling past them
        });
    }, []);

    const socialMedia = [
        { icon: faInstagram, link: 'https://www.instagram.com/nolawi_tutor?igsh=amY0aHpnZWxscHR1', name: 'Instagram' },
        { icon: faTelegram, link: 'https://t.me/nolawi_tutor', name: 'Telegram' },
        { icon: faLinkedinIn, link: 'https://www.linkedin.com', name: 'LinkedIn' },
        { icon: faTiktok, link: 'https://www.tiktok.com', name: 'TikTok' },
        { icon: faFacebookF, link: 'https://www.facebook.com', name: 'Facebook' },
    ];

    return (
        <div className="social-media-section" data-aos="fade-up">
            <h2 className="social-media-title" data-aos="fade-up">Follow Us on Social Media</h2>
            <div className="social-media-icons">
                {socialMedia.map((platform, index) => (
                    <a
                        key={index}
                        href={platform.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="social-media-link"
                        aria-label={platform.name}
                        data-aos="zoom-in"
                        data-aos-delay={`${index * 100}`}
                    >
                        <FontAwesomeIcon icon={platform.icon} />
                    </a>
                ))}
            </div>
        </div>
    );
}

export default SocialMediaLinks;
