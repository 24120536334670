import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './TestimonialSection.css';

function TestimonialSection() {
    const testimonials = [
        "She is always punctual, and I absolutely love the way she teaches. My daughter looks forward to every session!",
        "We’ve seen such great progress with our child that we definitely want to continue with NOLAWI’s services. The improvement is remarkable!",
        "After seeing the positive impact on my second child, I’m excited to have NOLAWI start tutoring my younger son as well. The results speak for themselves!",
        "The first month showed some progress, but as time went on, my daughter’s grades really started improving. NOLAWI's patience and dedication paid off!",
        "Due to personal reasons, I had to move, but I didn’t want to stop working with NOLAWI. Thanks to their wide network of tutors, they quickly provided a substitute closer to my new address.",
        "I really appreciate how engaged NOLAWI is with my child’s progress, and their continuous check-ups show how much they care. It’s made all the difference!",
        "Thanks to NOLAWI’s price differentiation and group packages, I’m now able to tutor all three of my children at a 50% discount. It's such a relief!",
        "The tutor’s approach is so personalized, and I can see my child becoming more confident with every lesson. NOLAWI truly makes learning enjoyable.",
        "I was skeptical at first, but now I’m a believer. NOLAWI has turned around my son’s attitude towards school, and his grades are better than ever.",
        "NOLAWI’s flexibility and commitment to quality education are outstanding. Even with my busy schedule, they’ve managed to accommodate us perfectly."
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    // Initialize AOS
    useEffect(() => {
        AOS.init({ duration: 1200 });
    }, []);

    // Autoplay functionality
    useEffect(() => {
        const interval = setInterval(() => {
            nextTestimonial();
        }, 7000); // Change slide every 7 seconds

        return () => clearInterval(interval);
    }, [currentIndex]);

    const nextTestimonial = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    };

    const prevTestimonial = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
        );
    };

    return (
        <section className="testimonials-section" data-aos="fade-up">
            <h2 className="section-title" data-aos="zoom-in">What Our Clients Say</h2>
            <div className="testimonials-container">
                <button className="slider-control prev" onClick={prevTestimonial} data-aos="fade-right">
                    &#10094;
                </button>
                <div className="testimonial active" data-aos="fade-in">
                    <p className="testimonial-text" data-aos="fade-up" data-aos-delay="200">
                        "{testimonials[currentIndex]}"
                    </p>
                </div>
                <button className="slider-control next" onClick={nextTestimonial} data-aos="fade-left">
                    &#10095;
                </button>
            </div>
            <div className="dots" data-aos="fade-up" data-aos-delay="400">
                {testimonials.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${index === currentIndex ? 'active' : ''}`}
                        onClick={() => setCurrentIndex(index)}
                    ></span>
                ))}
            </div>
        </section>
    );
}

export default TestimonialSection;
