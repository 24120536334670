import React, { useState, useEffect } from 'react';
import TutorCard from '../homepage/TutorCard'; // Ensure the path is correct
import { FaSearch } from 'react-icons/fa';
import './FindTutor.css';

const FindTutor = () => {
    const [tutors, setTutors] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredTutors, setFilteredTutors] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const tutorsPerPage = 6;

    // Fetch tutors from API on mount
    useEffect(() => {
        const fetchTutors = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/tutors/`);
                const data = await response.json();
                setTutors(data);
                setFilteredTutors(data); // Initialize the filtered tutors
            } catch (error) {
                console.error('Error fetching tutors:', error);
            }
        };

        fetchTutors();
    }, []);

    // Filter tutors based on search term
    useEffect(() => {
        const searchFiltered = tutors.filter(tutor => 
            tutor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            tutor.subject_expertise.toLowerCase().includes(searchTerm.toLowerCase()) // Fixed field name here
        );
        setFilteredTutors(searchFiltered);
        setCurrentPage(1); // Reset to first page on search
    }, [searchTerm, tutors]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value); // Update search term
    };

    // Pagination
    const indexOfLastTutor = currentPage * tutorsPerPage;
    const indexOfFirstTutor = indexOfLastTutor - tutorsPerPage;
    const currentTutors = filteredTutors.slice(indexOfFirstTutor, indexOfLastTutor);

    const totalPages = Math.ceil(filteredTutors.length / tutorsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="find-tutor-container">
            <h2 className="section-title">Find a Tutor</h2>

            <div className="search-bar-container">
                <input 
                    type="text"
                    className="search-bar"
                    placeholder="Search by name or subject..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <FaSearch className="search-icon" />
            </div>

            <div className="tutor-list">
                {currentTutors.length > 0 ? (
                    currentTutors.map(tutor => (
                        <TutorCard key={tutor.id} tutor={tutor} />
                    ))
                ) : (
                    <p>No tutors found.</p>
                )}
            </div>

            {/* Pagination */}
            {totalPages > 1 && (
                <div className="pagination">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                            onClick={() => handlePageChange(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default FindTutor;
