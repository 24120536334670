import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './HeroSection.css';
import HeroImage1 from '../images/HeroSection1.png';
import HeroImage2 from '../images/HeroSection2.png';
import HeroImage3 from '../images/HeroSection3.png';

const images = [HeroImage1, HeroImage2, HeroImage3];
const titles = [
    "Are you looking for a tutor? ",
    "Do you want to look at all our tutors",
    "Do you want to tutors on your part time? "
];
const subtitles = [
    "Connect with qualified tutors near you tailored to meet your specific academic needs.",
    "Explore our diverse roster of certified tutors and select the perfect match for your learning goals.",
    "Join our team of professional tutors and make a difference in students' lives."
];
const buttonLabels = [
    "Request a Tutor",
    "Choose a Tutor",
    "Become a Tutor"
];

function HeroSection() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isManual, setIsManual] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        let interval;
        if (!isManual) {
            interval = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
            }, 7000);
        }

        return () => clearInterval(interval);
    }, [currentIndex, isManual]);

    const handlePrevClick = () => {
        setIsManual(true);
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
        resetManual();
    };

    const handleNextClick = () => {
        setIsManual(true);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        resetManual();
    };

    const resetManual = () => {
        setTimeout(() => {
            setIsManual(false);
        }, 7000);
    };

    const handleButtonClick = () => {
        switch (currentIndex) {
            case 0:
                const form = document.querySelector("#contact-form-section");
                if (form) form.scrollIntoView({ behavior: 'smooth' });
                break;
            case 1:
                navigate('/FindTutor');
                break;
            case 2:
                navigate('/BecomeTutor');
                break;
            default:
                break;
        }
    };

    return (
        <div className="hero-wrapper">
            <div className="hero-section">
                <div className="hero-slide">
                    <img src={images[currentIndex]} alt={titles[currentIndex]} className="hero-image" />
                    <div className="overlay"></div>
                    <div className="hero-content">
                        <h1 className="hero-title animate-title">{titles[currentIndex]}</h1>
                        <p className="hero-subtitle animate-subtitle">{subtitles[currentIndex]}</p>
                        <button className="hero-btn animate-btn" onClick={handleButtonClick}>{buttonLabels[currentIndex]}</button>
                    </div>
                    <button className="prev" onClick={handlePrevClick}>
                        <span>&lt;</span>
                    </button>
                    <button className="next" onClick={handleNextClick}>
                        <span>&gt;</span>
                    </button>
                </div>
                <div className="hero-controls">
                    {images.map((_, index) => (
                        <span
                            key={index}
                            className={`hero-control-dot ${index === currentIndex ? 'active' : ''}`}
                            onClick={() => {
                                setIsManual(true);
                                setCurrentIndex(index);
                                resetManual();
                            }}
                        ></span>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default HeroSection;
