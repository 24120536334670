import React from 'react';
import './TopBanner.css';

function TopBanner() {
    return (
        <div className="top-banner">
            <div className="contact-info">
                <span className="contact-item">
                    <i className="fas fa-phone-alt"></i> 
                    <a href="tel:+251911148819" className="call-now-button">Call Now: 09-11-14-88-19</a>
                </span>
            </div>
        </div>
    );
}

export default TopBanner;
